import React, { Component } from 'react'
import {Navbar, Nav, NavDropdown, Form, FormControl, Button} from 'react-bootstrap';
// import Map from './Map';
import { Row, Container } from "reactstrap";

const NavbarComp = () => {
    console.log("NavBar");
    return (
      <div>
          <Navbar bg="light" expand="lg">
            <Container>
                <Navbar.Brand href="#home">SarahCafora.com</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link href="#home">Home</Nav.Link>
                    <Nav.Link href="#link">Blogs</Nav.Link>
                    <NavDropdown title="Websites" id="basic-nav-dropdown">
                    <NavDropdown.Item href="#action/3.1">Clothing Line</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
                </Navbar.Collapse>
            </Container>
            </Navbar>
      </div>
    )
  }
export default NavbarComp;