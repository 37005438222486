import "./Intro.css"
import Shoe from "../img/sarahStella.png"
import React, { useEffect, useRef } from "react";

const Introduction = () => {
    let isMoving = true;
    let myTimeout;
    let boxWidth;

    // const child1 = useRef(null);

    // useEffect(() => {
    //   console.log("myContainer..", child1.current);
    //   console.log("myContainer..", child1);

    // });

    const boxMouseOverHandler = (event: React.MouseEvent<HTMLDivElement>) => {
        const box: HTMLDivElement = event.currentTarget;
        boxWidth = box.offsetWidth;
        console.log("Box width", boxWidth);
        console.log('hover');
        // box.style.transform = "rotate(12deg)";
        // box.style.backgroundColor = "lightblue";
        let xVelocity = -100;
        myTimeout = setInterval(function(){
            console.log("Sup!"); 
            console.log(box.offsetRight);
             boxWidth -= 10;
            xVelocity += 5;

            //  box.style.width = boxWidth.toString() + "px";
             box.style.left = xVelocity +  "px";
             console.log("X: " + box.offsetRight + xVelocity);
             }, 50);
            
        };

      const boxMouseOutHandler = (event: React.MouseEvent<HTMLDivElement>) => {
        const box: HTMLDivElement = event.currentTarget;
        // box.style.backgroundColor = "lightgreen";
        // box.style.transform = "rotate(0deg)";
        isMoving = false;
        box.style.left =0;
        clearInterval(myTimeout);
      };

    return (
        <div className="i d-flex">
            <div className="i-right" onMouseOver={boxMouseOverHandler} onMouseOut={boxMouseOutHandler}>
                {/* <div className="i-bg"></div> */}
                <img src={Shoe} alt="" className="i-img"/>
            </div>

            {/* <div className="build-wrap">
                <div className="build-group d-flex">
                    <div className="build" ref={child1} id="b1"></div>
                    <div className="build" ref={child2} id="b2"></div>
                    <div className="build" ref={child3} id="b3"></div>
                    <div className="build" ref={child4} id="b4"></div>
                </div>
            </div> */}
        </div>
    )
}

export default Introduction;