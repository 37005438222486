export const products = [
    {
        id: 1,
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8PQAgCPE4NA5OB0Oz9lzXrVvuNSUh5llOjQ&usqp=CAU",
        link: "http://sarahcafora.com"
    },
    {
        id: 2,
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwe8FQ6-Ois5Sz_RknI05YENfeRA5116PvWw&usqp=CAU",
        link: "http://sarahcafora.com"
    },
    {
        id: 3,
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeWzaF9Jy1V1veXTPM6gOyf2JjEILBGpE6rg&usqp=CAU",
        link: "http://sarahcafora.com"
    },
    {
        id: 4,
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSw63JoXe1fttweW7fC1oCSCM7SmdCY-uOwFg&usqp=CAU",
        link: "http://sarahcafora.com"
    },
    {
        id: 5,
        img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDKYVO01ifYnBPALNpdJIS9UDkKf6kmyD9uA&usqp=CAU",
        link: "http://sarahcafora.com"
    },

]